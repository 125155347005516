import { createSlice } from "@reduxjs/toolkit";
import { APPLICATION_MENU_COUNTERS } from "constants/AuthConstant";


export const initialState = {
    applications: {
        'application-pending': localStorage.getItem(APPLICATION_MENU_COUNTERS) ? JSON.parse(localStorage.getItem(APPLICATION_MENU_COUNTERS))['application-pending'] : 0,
        'application-valuated': localStorage.getItem(APPLICATION_MENU_COUNTERS) ? JSON.parse(localStorage.getItem(APPLICATION_MENU_COUNTERS))['application-valuated'] : 0,
        'application-scheduled': localStorage.getItem(APPLICATION_MENU_COUNTERS) ? JSON.parse(localStorage.getItem(APPLICATION_MENU_COUNTERS))['application-scheduled'] : 0,
        'application-finalized': localStorage.getItem(APPLICATION_MENU_COUNTERS) ? JSON.parse(localStorage.getItem(APPLICATION_MENU_COUNTERS))['application-finalized'] : 0,
    }
};


export const menuCountersSlice = createSlice({
    name: 'MenuCounter',
    initialState,
    reducers: {
        setApplicationCounters: (state, action) => {
            state.applications = action.payload;
            localStorage.setItem(APPLICATION_MENU_COUNTERS, JSON.stringify(action.payload));
        },
    },
});

export const { setApplicationCounters } = menuCountersSlice.actions;


export default menuCountersSlice.reducer;