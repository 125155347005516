import { BACKEND_BASE_URL } from "configs/BackendConfig";

export const isValidValue = (value) => {
    return value !== undefined && value !== null && value !== '';
};


export const setFileUrl = (file) => {
    if (isValidValue(file) === false) {
        return null;
    }

    if (file?.toString()?.includes(BACKEND_BASE_URL) === true)
        return file;

    return `${BACKEND_BASE_URL}${file}`;
};


export const setFormFieldValue = (value) => {
    const isValid = isValidValue(value);

    return isValid ? value : null;
};


export const splitPhonePrefix = (phone, prefix) => {
    if (isValidValue(phone) === false) {
        return null;
    }

    const phoneParts = phone.split(prefix);
    if (phoneParts.length === 2) {
        return phoneParts[1];
    }

    return phone;
};