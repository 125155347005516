import { createSlice } from "@reduxjs/toolkit";
import { NOTIFICATIONS, UNSEEN_NOTIFICATIONS } from "constants/AuthConstant";


export const initialState = {
    notifications: JSON.parse(localStorage.getItem(NOTIFICATIONS)) || [],
    unseen: localStorage.getItem(UNSEEN_NOTIFICATIONS) || 0,
    total: 0,
};


export const notificationSlice = createSlice({
    name: 'Notification',
    initialState,
    reducers: {
        setNotifications: (state, action) => {
            state.notifications = action.payload;
            localStorage.setItem(NOTIFICATIONS, JSON.stringify(action.payload));
        },
        setUnseen: (state, action) => {
            state.unseen = action.payload;
            localStorage.setItem(UNSEEN_NOTIFICATIONS, action.payload);
        },
        setTotal: (state, action) => {
            state.total = action.payload;
        },
    },
});

export const { setNotifications, setUnseen, setTotal } = notificationSlice.actions;


export default notificationSlice.reducer;