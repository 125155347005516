import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import AuthService from 'services/auth/AuthService';
import FirebaseService from 'services/FirebaseService';
import { setAddress, setMember, setRole } from './MemberSlice';
import { setPermissions } from './PermissionSlice';
import { setFormFieldValue } from 'helpers/FormHelper';
import { setRowNumber } from 'helpers/FilterDataHelper';


export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null
};


export const signIn = createAsyncThunk('auth/signIn', async (data, { dispatch, rejectWithValue }) => {
	try {
		const response = await AuthService.signIn(data);
		const responseData = response.data;
		const { success, result } = responseData;
		if (success === true) {
			const token = result.accessToken;
			const memberData = result.member;
			const memberRole = setFormFieldValue(memberData?.memberRole);
			const memberAddress = setFormFieldValue(memberData?.address);
			const permissions = setRowNumber(result.permissions);
			dispatch(authenticated(token));
			dispatch(setMember(memberData));
			dispatch(setRole(memberRole));
			dispatch(setAddress(memberAddress));
			dispatch(setPermissions(permissions));
			localStorage.setItem(AUTH_TOKEN, token);
			return token;
		} else {
			return rejectWithValue(responseData.message || 'Error')
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});


export const signUp = createAsyncThunk('auth/signUp', async (data, { rejectWithValue }) => {
	const { email, password } = data
	try {
		const response = await FirebaseService.signUpEmailRequest(email, password)
		if (response.user) {
			const token = response.user.refreshToken;
			localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
			return token;
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});


export const signOut = createAsyncThunk('auth/signOut', async ({ dispatch }) => {
	localStorage.clear(); 
	dispatch(setMember(null));
	dispatch(setRole(null));
	dispatch(setAddress(null));
	dispatch(setPermissions([]));
	return null;
});


export const authSlice = createSlice({
	name: 'Auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signUp.pending, (state) => {
				state.loading = true
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
});


export const {
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess
} = authSlice.actions;


export default authSlice.reducer;