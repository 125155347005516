import { combineReducers } from 'redux';
import Theme from './slices/ThemeSlice';
import Auth from './slices/AuthSlice';
import Member from './slices/MemberSlice';
import Notification from './slices/NotificationSlice';
import Permission from './slices/PermissionSlice';
import MenuCounters from './slices/MenuCounterSlice';


const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        Theme,
        Auth,
        Member,
        Permission,
        MenuCounters,
        Notification,
        ...asyncReducers,
    });
    return combinedReducer(state, action);
};
  

export default rootReducer;