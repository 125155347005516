// 1. BACKEND BASE URL
// export const BACKEND_BASE_URL = 'http://192.168.100.15:2000';
export const BACKEND_BASE_URL = 'https://neblejmecdomakine.mexhitkurti.com';


// 2. APIs
export const LOGIN = '/api/auth/login/'
export const LOGOUT = '/api/auth/logout/'
export const CHANGE_PASSWORD = '/api/auth/password/change/'
export const VERIFY_EMAIL = '/api/auth/email/verify/'
export const CONFIRM_CODE = '/api/auth/code/confirm/'

// 3. ADMINISTRATION
export const CHECKPOINTS = '/api/core/administration/checkpoint/'
export const MEMBERS = '/api/hr/member/backoffice/'
export const COMPLETE_PROFILE = '/api/hr/member/profile/complete/'
export const MEMBER_ROLES = '/api/hr/member/role/'
export const CAR_COLORS = '/api/core/car/color/'
export const CAR_TYPES = '/api/core/car/body/type/'
export const CAR_BRANDS = '/api/core/car/brand/'
export const CAR_MODELS = '/api/core/car/model/'
export const CAR_VARIANTS = '/api/core/car/variant/'
export const REVIEWS = '/api/report/customer/review/'
export const CONTACTS = '/api/core/administration/customer/contact/'
export const PERMISSIONS_TREE = '/api/core/access/permission/tree/'
export const PERMISSIONS_LIST = '/api/core/access/permission/view/'
export const ROLE_PERMISSIONS_TREE = '/api/core/access/permission/role/tree/'
export const ROLE_PERMISSIONS_UPDATE = '/api/core/access/permission/role/update/'


// 4. SETTTINGS
export const STATUSES = '/api/core/settings/status/'
export const CHECKPOINT_TYPES = '/api/core/settings/checkpoint/type/'
export const APPLICATION_TYPES = '/api/core/settings/application/type/'
export const NOTIFICATION_TYPES = '/api/core/settings/notification/type/'
export const BOOKING_TIMES = '/api/core/settings/booking/time/'
export const COUNTRIES = '/api/core/settings/country/'
export const DISTRICTS = '/api/core/settings/district/'

// 5. APPLICATIONS
export const PURCHASE_APPLICATIONS = "/api/application/purchase/formular/"
export const PENDING_PURCHASE_APPLICATIONS = "/api/application/purchase/pending/"
export const VALUDATED_PURCHASE_APPLICATIONS = "/api/application/purchase/valuated/"
export const SCHEDULED_PURCHASE_APPLICATIONS = "/api/application/purchase/scheduled/"
export const FINALIZED_PURCHASE_APPLICATIONS = "/api/application/purchase/finalized/"
export const REFUSE_PURCHASE_APPLICATION = "/api/application/purchase/refuse/"
export const APPROVE_PURCHASE_APPLICATION = "/api/application/purchase/approve/"
export const SCHEDULE_PURCHASE_APPLICATION = "/api/application/purchase/schedule/"
export const FINALIZE_PURCHASE_APPLICATION = "/api/application/purchase/finalize/"

// 6. DASHBOARD
export const DASHBOARD_COUNTERS = "/api/report/dashboard/counters/"
export const DASHBOARD_APPLICATION_STATUS_STATS = "/api/report/dashboard/application/statistics/status/"
export const DASHBOARD_APPLICATION_MONTHLY_STATS = "/api/report/dashboard/application/statistics/monthly/"
export const DASHBOARD_EXPENSE_MONTHLY_STATS = "/api/report/dashboard/expense/statistics/monthly/"
export const APPLICATION_COUNTRY_STATUS_STATS = "/api/report/statistics/country/status/"
export const APPLICATION_TOP_BRAND_STATS = "/api/report/statistics/brand/"
export const APPLICATION_TOP_DISTRICT_STATS = "/api/report/statistics/district/"
export const APPLICATION_PERIOD_STATS = "/api/report/statistics/period/"
export const MENU_COUNTERS = "/api/report/statistics/menu/counters/"

// 7. PROFILE
export const MY_LATEST_NOTIFICATIONS = "/api/core/settings/notifications/latest/";
export const MY_NOTIFICATIONS = "/api/core/settings/notifications/view/";
