import * as url from "configs/BackendConfig";
import axios from 'axios';



const AuthService = {};


AuthService.signIn = async (loginData, lang) => {
	const data = {
		username: loginData.email,
		password: loginData.password
	};

	const config = {
		headers: {
            'Lang': lang,
			'Content-Type': "application/json",
		},
	};

	const requestUrl = url.BACKEND_BASE_URL + url.LOGIN;
	return await axios.post(requestUrl, data, config);
};


AuthService.signOut = async (token, lang) => {
	const data = {};

	const tokenHeader = "Bearer " + token;
	const config = {
		headers: {
            'Lang': lang,
			'Content-Type': "application/json",
			'Authorization': tokenHeader
		},
	};

	const requestUrl = url.BACKEND_BASE_URL + url.LOGOUT;
	return await axios.post(requestUrl, data, config);
};


export default AuthService;