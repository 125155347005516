import { isValidValue } from "./FormHelper";


export const setRowNumber = (items) => {
    const isValidItems = isValidValue(items);
    if (isValidItems === false)
        return [];
    
    const formattedItems = items?.map((item, index) => {
        item.rowNumber = index + 1;
        return item;
    });

    return formattedItems;
};