import { createSlice } from "@reduxjs/toolkit";
import { ADDRESS, MEMBER, ROLE } from "constants/AuthConstant";


export const initialState = {
    data: JSON.parse(localStorage.getItem(MEMBER)) || null,
    role: JSON.parse(localStorage.getItem(ROLE)) || null,
    address: JSON.parse(localStorage.getItem(ADDRESS)) || null,
};


export const memberSlice = createSlice({
    name: 'Member',
    initialState,
    reducers: {
        setMember: (state, action) => {
            state.data = action.payload;
            localStorage.setItem(MEMBER, JSON.stringify(action.payload));
        },
        setRole: (state, action) => {
            state.role = action.payload;
            localStorage.setItem(ROLE, JSON.stringify(action.payload));
        },
        setAddress: (state, action) => {
            state.address = action.payload;
            localStorage.setItem(ADDRESS, JSON.stringify(action.payload));
        },
        logout: (state) => {
            state.data = null;
            state.role = null;
            localStorage.removeItem(MEMBER);
            localStorage.removeItem(ROLE);
        },
    },
});

export const { setMember, setRole, setAddress, logout } = memberSlice.actions;


export default memberSlice.reducer;