import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'auth.login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'auth.email.verify',
        path: `${AUTH_PREFIX_PATH}/email/verify`,
        component: React.lazy(() => import('views/auth-views/authentication/verify-email')),
    },
    {
        key: 'auth.code.set',
        path: `${AUTH_PREFIX_PATH}/code/confirm`,
        component: React.lazy(() => import('views/auth-views/authentication/set-code')),
    },
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'dashboard.statistics',
        path: `${APP_PREFIX_PATH}/dashboards/stats`,
        component: React.lazy(() => import('views/app-views/dashboards/stats')),
    },
    {
        key: 'application.register',
        path: `${APP_PREFIX_PATH}/application/register`,
        component: React.lazy(() => import('views/app-views/applications/new-application')),
    },
    {
        key: 'application.pending',
        path: `${APP_PREFIX_PATH}/application/pending`,
        component: React.lazy(() => import('views/app-views/applications/pending-applications')),
    },
    {
        key: 'application.valuated',
        path: `${APP_PREFIX_PATH}/application/valuated`,
        component: React.lazy(() => import('views/app-views/applications/valuated-applications')),
    },
    {
        key: 'application.scheduled',
        path: `${APP_PREFIX_PATH}/application/scheduled`,
        component: React.lazy(() => import('views/app-views/applications/scheduled-applications')),
    },
    {
        key: 'application.finalized',
        path: `${APP_PREFIX_PATH}/application/finalized`,
        component: React.lazy(() => import('views/app-views/applications/finalized-applications')),
    },
    {
        key: 'application.view',
        path: `${APP_PREFIX_PATH}/application/view`,
        component: React.lazy(() => import('views/app-views/applications/view-applications')),
    },
    {
        key: 'administration.client.service.review',
        path: `${APP_PREFIX_PATH}/administration/client/service/review`,
        component: React.lazy(() => import('views/app-views/administration/client-service/reviews')),
    },
    {
        key: 'administration.client.service.contact',
        path: `${APP_PREFIX_PATH}/administration/client/service/contact`,
        component: React.lazy(() => import('views/app-views/administration/client-service/contacts')),
    },
    {
        key: 'administration.access.permission',
        path: `${APP_PREFIX_PATH}/administration/access/permission`,
        component: React.lazy(() => import('views/app-views/administration/access/user-permissions')),
    },
    {
        key: 'administration.access.role',
        path: `${APP_PREFIX_PATH}/administration/access/role`,
        component: React.lazy(() => import('views/app-views/administration/access/role-permissions')),
    },
    {
        key: 'administration.user.role',
        path: `${APP_PREFIX_PATH}/administration/user/role`,
        component: React.lazy(() => import('views/app-views/administration/users/member-roles')),
    },
    {
        key: 'administration.user.member',
        path: `${APP_PREFIX_PATH}/administration/user/member`,
        component: React.lazy(() => import('views/app-views/administration/users/members')),
    },
    {
        key: 'administration.car.color',
        path: `${APP_PREFIX_PATH}/administration/car/color`,
        component: React.lazy(() => import('views/app-views/administration/car/car-colors')),
    },
    {
        key: 'administration.car.body.type',
        path: `${APP_PREFIX_PATH}/administration/car/body/type`,
        component: React.lazy(() => import('views/app-views/administration/car/car-types')),
    },
    {
        key: 'administration.car.brand',
        path: `${APP_PREFIX_PATH}/administration/car/brand`,
        component: React.lazy(() => import('views/app-views/administration/car/car-brands')),
    },
    {
        key: 'administration.car.model',
        path: `${APP_PREFIX_PATH}/administration/car/model`,
        component: React.lazy(() => import('views/app-views/administration/car/car-models')),
    },
    {
        key: 'administration.car.variant',
        path: `${APP_PREFIX_PATH}/administration/car/variant`,
        component: React.lazy(() => import('views/app-views/administration/car/car-variants')),
    },
    {
        key: 'administration.checkpoint',
        path: `${APP_PREFIX_PATH}/administration/checkpoint`,
        component: React.lazy(() => import('views/app-views/administration/checkpoints')),
    },
    {
        key: 'settings.status',
        path: `${APP_PREFIX_PATH}/settings/status`,
        component: React.lazy(() => import('views/app-views/settings/application-statuses')),
    },
    {
        key: 'settings.booking.time',
        path: `${APP_PREFIX_PATH}/settings/booking/time`,
        component: React.lazy(() => import('views/app-views/settings/booking-times')),
    },
    {
        key: 'settings.type.checkpoint',
        path: `${APP_PREFIX_PATH}/settings/type/checkpoint`,
        component: React.lazy(() => import('views/app-views/settings/typologies/checkpoint-types')),
    },
    {
        key: 'settings.type.application',
        path: `${APP_PREFIX_PATH}/settings/type/application`,
        component: React.lazy(() => import('views/app-views/settings/typologies/application-types')),
    },
    {
        key: 'settings.type.notification',
        path: `${APP_PREFIX_PATH}/settings/type/notification`,
        component: React.lazy(() => import('views/app-views/settings/typologies/notification-types')),
    },
    {
        key: 'settings.regional.country',
        path: `${APP_PREFIX_PATH}/settings/regional/country`,
        component: React.lazy(() => import('views/app-views/settings/regionals/countries')),
    },
    {
        key: 'settings.regional.district',
        path: `${APP_PREFIX_PATH}/settings/regional/district`,
        component: React.lazy(() => import('views/app-views/settings/regionals/districts')),
    },
    {
        key: 'profile.account',
        path: `${APP_PREFIX_PATH}/profile`,
        component: React.lazy(() => import('views/app-views/profile/account')),
    },
    {
        key: 'profile.notification',
        path: `${APP_PREFIX_PATH}/profile/notification`,
        component: React.lazy(() => import('views/app-views/profile/account/management/components/Notifications')),
    },
    {
        key: 'profile.settings',
        path: `${APP_PREFIX_PATH}/profile/settings`,
        component: React.lazy(() => import('views/app-views/profile/account/management/components/Settings')),
    },
    {
        key: 'profile.password.change',
        path: `${APP_PREFIX_PATH}/profile/password/change`,
        component: React.lazy(() => import('views/app-views/profile/account/management/components/ChangePassword')),
    },
]