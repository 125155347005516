import { createSlice } from "@reduxjs/toolkit";
import { PERMISSIONS } from "constants/AuthConstant";


export const initialState = {
    permissions: JSON.parse(localStorage.getItem(PERMISSIONS)) || [],
};


export const permissionSlice = createSlice({
    name: 'Permission',
    initialState,
    reducers: {
        setPermissions: (state, action) => {
            state.permissions = action.payload;
            localStorage.setItem(PERMISSIONS, JSON.stringify(action.payload));
        },
    },
});

export const { setPermissions } = permissionSlice.actions;


export default permissionSlice.reducer;